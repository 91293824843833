<template>
  <div class="page">
    <div class="banner">
      <img src="./img/listbanner.png" alt="" />
    </div>
    <div class="main-title">
      {{ dateStr }}<br />勤学专线下午班车报名人数查看
    </div>
    <div class="tab">
      <div class="item">学生姓名</div>
      <div class="item">所在班级</div>
      <div class="item">联系家长</div>
      <div class="item">当日签到</div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="name">{{ item.childrenName }}</div>
        <div class="class">{{ item.childrenClass }}</div>
        <div class="img" @click="calling(item)">
          <img src="./img/tel.png" />
        </div>
        <div class="btn">
          <div
            class="sginBtn"
            v-if="item.signStatus == 0"
            @click="signRightNow(item)"
          >
            签到
          </div>
          <div class="sginedBtn" v-else>已签到</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSignInfoUrl, changeSignUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "studySignList",
  data() {
    return {
      dateStr: "",
      list: [],
    };
  },
  async created() {
    this.getDateStr();
    await this.getList();
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  methods: {
    calling(item) {
      window.location.href = `tel://${item.parentsPhone}`;
    },
    signRightNow(item) {
      let params = {
        id: item.id,
        signStatus: 1,
        userId: this.userId,
      };
      this.$axios
        .post(`${changeSignUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.$toast("签到成功~");
            this.getList();
          }
        });
    },
    getDateStr() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      this.dateStr = `${year}年${month}月${day}日`;
    },
    async getList() {
      let params = {
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getSignInfoUrl}`, { params: params });
      if (res.code == 200) {
        this.list = res.data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  padding: 36px 30px 116px;
  box-sizing: border-box;
  .banner {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    img {
      width: 100%;
    }
  }
  .main-title {
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    padding-bottom: 48px;
    line-height: 44px;
  }
  .tab {
    width: 100%;
    height: 88px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
    border-radius: 8px;
    display: flex;
    align-content: center;
    line-height: 88px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 26px;
    .item {
      flex: 1;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .list {
    width: 100%;
    min-height: 1036px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
    border-radius: 8px;
    padding: 32px 0;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 60px;
      margin-bottom: 36px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      .name,
      .class {
        flex: 1;
        text-align: center;
      }
      .img {
        flex: 1;
        img {
          margin: 0 auto;
          display: block;
          width: 34px;
        }
      }
      .btn {
        flex: 1;
        .sginBtn {
          margin: 0 auto;
          width: 140px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          background: #007eff;
          border-radius: 48px;
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
        }
        .sginedBtn {
          margin: 0 auto;
          width: 140px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 48px;
          border: 2px solid #007eff;
          font-size: 24px;
          font-weight: 600;
          color: #007eff;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
